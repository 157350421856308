<template>
  <page-main
    title="Getting specific about a company."
    :aside="['']"
    nextText="Let's work on your strengths"
    buttonTabIndex="6"
    eventName="nav"
    @nav="nav('/Strengths/')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="targetCompany"
            label="What is the name of the company?"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyDoes"
            label="What does the company do?"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="targetRole"
            label="What position or role are you interested in? (One)"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="companyReasons"
            label="What about the target company and role is interesting or attractive to you?"
            rows="1"
            autogrow
            tabindex="4"
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="companyGoals"
            label="What do you want to get out of working for this company?"
            rows="1"
            autogrow
            tabindex="5"
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col"> </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  name: "TargetIndustry",
  data: () => ({}),
  mounted() {},
  methods: {
    save() {
      this.saveTargetCompany();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("targetCompany", ["saveTargetCompany"])
  },
  computed: {
    ...mapFields("targetCompany", [
      "targetCompany",
      "companyDoes",
      "targetRole",
      "companyReasons",
      "companyGoals"
    ])
  }
};
</script>
